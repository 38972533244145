<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <vue-good-table
              :columns="columns"
              :rows="studentsPracticalResearch"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <b-button
                    v-if="isShowCheckExamination(props.row)"
                    v-b-modal.practicalResearchCheckScoreModal
                    variant="flat-primary"
                    class="btn-icon"
                    size="sm"
                    title="Phúc khảo điểm thi"
                    @click="onShowPracticalResearchCheckScore(props.row)"
                  >
                    <feather-icon icon="SendIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'timeStart'">
                  {{ formatDate(props.row.timeStart) }}
                </span>
                <span v-else-if="props.column.field === 'examTime'">
                  {{ formatExamTime(props.row.examTime) }}
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
        <PracticalResearchCheckScore
          ref="practicalResearchCheckScoreModal"
          :practical-research-class-student-id="practicalResearchClassStudentId"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>

import {
  BButton,
  BCard, BCardBody, BCol, BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PracticalResearchCheckScore from '@/views/practical-research/PracticalResearchCheckScore.vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PracticalResearch',
  components: {
    PracticalResearchCheckScore,
    BButton,
    BPagination,
    BFormSelect,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BModal,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Chương trình nghiên cứu',
          field: 'practicalResearchProgram',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Địa điểm',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trưởng đoàn',
          field: 'teacher',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thời gian bắt đầu',
          field: 'timeStart',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Loại bài thi',
          field: 'examTime',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm tổng kết',
          field: 'score',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm sau phúc khảo',
          field: 'checkScore',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: true,
          thClass: 'text-center',
        },
      ],
      practicalResearchClassStudentId: null,
    }
  },
  computed: {
    ...mapGetters({
      studentsPracticalResearch: 'practicalResearchOutcome/studentsPracticalResearch',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    studentClassId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.studentClassId : null
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.readStudentsPracticalResearchByGroup({ studentClassId: this.studentClassId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readStudentsPracticalResearchByGroup: 'practicalResearchOutcome/readStudentsPracticalResearch',
    }),
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    isShowCheckExamination(data) {
      return !(data.statusCheckScore === 2)
    },
    onShowPracticalResearchCheckScore(row) {
      this.practicalResearchClassStudentId = row.id
    },
    formatDate(date) {
      return date ? moment(date).format('DD-MM-YYYY') : ''
    },
    formatExamTime(data) {
      return data === 1 ? 'Bài thi lần 1' : 'Bài thi lần 2'
    },
  },
}
</script>

<style scoped lang="scss">
.status-review{
  background-color: rgb(40, 167, 69);
  padding: 5px;
  color: white;
  border-radius: 5px;
}
.padding-5 {
  padding: 5px;
}
.br-5 {
  border-radius: 5px;
}
.row-selected {
  background-color: rgba(251, 205, 68, 0.89);
  color: #FFF;
}
</style>
